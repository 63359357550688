import React, { useContext, useEffect, useState, Fragment } from "react";
import { observer } from "mobx-react-lite";
import {
    Tab,
    TableBody,
    TableCell,
    Table,
    TableRow,
    TableFooter,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import CompetitionList from "./CompetitionList";
import CompetitionRanking from "./CompetitionRanking";
import CompetitionHighlights from "./CompetitionHighlights";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";

const CompetitionDashboard = () => {
    const rootStore = useContext(RootStoreContext);
    const {
        loadingInitial,
        loadAvailableData,
        availableData,
        activeTabIndex,
        setActiveTab,
    } = rootStore.competitionStore;

    const [panes, setPanes] = useState<any[]>([]);

    useEffect(() => {
        loadAvailableData();
    }, [loadAvailableData]);

    useEffect(() => {
        let temp: any[] = [];

        availableData.map((year) => {
            if (year === 20202021) {
                temp.push({
                    menuItem: `Tabelle 2020/2021`,
                    render: () => <CompetitionRanking year={20202021} />,
                });
            }
            else {
                temp.push(
                    {
                        menuItem: `Tabelle ${year}`,
                        render: () => <CompetitionRanking year={year} />,
                    },
                    {
                        menuItem: `Highlights ${year}`,
                        render: () => <CompetitionHighlights year={year} />,
                    },
                    {
                        menuItem: `Details ${year}`,
                        render: () => <CompetitionList year={year} />,
                    }
                )
            }
            return temp;
        }
        );

        setPanes(temp);
    }, [availableData]);

    if (loadingInitial) return <LoadingComponent content="Lade Competitions" />;

    return (
        <Fragment>
            <Table style={{ marginBottom: 14 }} unstackable>
                <TableBody>
                    <TableRow>
                        <TableCell textAlign="right">Spielort</TableCell>
                        <TableCell>
                            <Link to="/garage">
                                Cafe & Bar Garage, Rennweg 12-14, Nürnberg
                            </Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right">Tag</TableCell>
                        <TableCell>jeden Donnerstag</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right">Beginn</TableCell>
                        <TableCell>19:00</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right" collapsing>
                            Anmeldung
                        </TableCell>
                        <TableCell>bis 5 Minuten vor Beginn vor Ort oder <a href="https://2k-dart-software.com/frontend/events/1/registration/76/register">HIER ONLINE</a> </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right">Boards</TableCell>
                        <TableCell>4 (geschrieben wird auf PC)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right">Modus</TableCell>
                        <TableCell>
                            Doppel-KO, 501 Double Out, Gewinnerseite mind. B.o.5,
                            Verliererseite mind. B.o.3
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right">Startgeld</TableCell>
                        <TableCell>3,00€</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right">Teilnahme</TableCell>
                        <TableCell>Jeder Interessierte ist herzlich willkommen. Egal ob vereinsloser Einsteiger oder langjähriger Spieler.
                            Bei Fragen schreibt uns auf Social Media oder unter <a href="mailto:info@finnigans-harp.de">info@finnigans-harp.de</a></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell textAlign="right" collapsing>Ewige Rangliste</TableCell>
                        <TableCell>Seit der Saison 1998 erfassen wir alle Teilnahmen in unserer <Link to="/eternalranking">ewigen Rangliste</Link></TableCell>
                    </TableRow>
                </TableBody>
                <TableFooter fullWidth>
                    <TableRow>
                        <TableCell colSpan="2">
                            Die Anzahl der ausgezahlten Plätze am Ende der Saison, richtet sich nach der durchschnittlichen Teilnehmerzahl.<br />
                            Die Competition Saison 2025 hat am <b>09.01.2025</b>.<br/>
                            Letzter Spieltag ist der <b>11.12.2025</b>.<br />                                              
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>

            <MediaQuery minWidth={1024}>

                <Tab
                    panes={panes}
                    menu={{ fluid: true, vertical: true }}
                    menuPosition="right"
                    activeIndex={activeTabIndex}
                    onTabChange={(e, data) => setActiveTab(data.activeIndex)}
                ></Tab>
            </MediaQuery>
            <MediaQuery maxWidth={1024}>
                <Tab
                    panes={panes}
                    menu={{ fluid: true, vertical: false, stackable: true }}
                    activeIndex={activeTabIndex}
                    onTabChange={(e, data) => setActiveTab(data.activeIndex)}
                ></Tab>
            </MediaQuery>
        </Fragment>
    );
};

export default observer(CompetitionDashboard);
