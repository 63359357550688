import React, { useContext } from "react";
import {
    Menu,
    Container,
    Button,
    Dropdown,
    DropdownItem,
    Icon,
} from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { NavLink, Link } from "react-router-dom";
import { RootStoreContext } from "../../app/stores/rootStore";
import { LoginForm } from "../user/LoginForm";
import MediaQuery from 'react-responsive'

const NavBar: React.FC = () => {
    const rootStore = useContext(RootStoreContext);
    const { user, logout, isLoggedIn } = rootStore.userStore;
    const { openModal } = rootStore.modalStore;

    return (
        <div>
            <div>
                <Menu fixed="top" inverted stackable>
                    <MediaQuery maxWidth={1024}>
                        <Container>
                            <Dropdown
                                item
                                floating
                                labeled
                                button
                                className='icon' icon='bullseye' text="Finnigan's Harp"
                                style={{ marginLeft: 10 }} >
                                <Dropdown.Menu>
                                    <DropdownItem as={NavLink} exact to="/">Home</DropdownItem>
                                    <DropdownItem name="Die Garage" as={NavLink} to="/garage" >Die Garage</DropdownItem>
                                    <DropdownItem name="Teams" as={NavLink} to="/teams" >Teams</DropdownItem>
                                    <DropdownItem name="Competition" as={NavLink} to="/competitions" >Competition</DropdownItem>
                                    <DropdownItem name="EinzelLiga" as={NavLink} to="/singleleague" >Einzel Liga</DropdownItem>
                                    <DropdownItem href="https://www.facebook.com/Finnigansharpnuernberg" target='_blank' ><Icon name="facebook" /></DropdownItem>
                                    <DropdownItem href="https://www.instagram.com/finnigans_harp_nuremberg/" target='_blank' ><Icon name="instagram" /></DropdownItem>
                                    <DropdownItem href="https://whatsapp.com/channel/0029VaKzCIf05MUjVyTOya3L" target='_blank' ><Icon name="whatsapp" /></DropdownItem>
                                    <DropdownItem href="https://www.youtube.com/@FinnigansHarp1984/streams" target='_blank' ><Icon name="youtube" /></DropdownItem>
                                    <Dropdown item text="Veranstaltungen">
                                    <Dropdown.Menu>
                                        <DropdownItem as={Link}
                                                to={'/tournament'}

                                            text="Turniere" />
                                        <DropdownItem as={Link}
                                            to={'/event'}
                                            text="Messe & Firmenevents" />
                                    </Dropdown.Menu>
                                </Dropdown>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Container>
                    </MediaQuery >
                    <MediaQuery minWidth={1024}>
                        <Container>
                            <Menu.Item header as={NavLink} exact to="/">
                                <Icon inverted name="bullseye" style={{ marginRight: 10 }} color='grey' />
                                Finnigan's Harp
                            </Menu.Item>
                            <Menu.Item name="Die Garage" as={NavLink} to="/garage" />
                            <Menu.Item name="Teams" as={NavLink} to="/teams" />
                            <Menu.Item name="Competition" as={NavLink} to="/competitions" />
                            <Menu.Item name="Einzel Liga" as={NavLink} to="/singleleague" />
                           {/* <Menu.Item name="Sponsoren" as={NavLink} to="/sponsors" />*/}
                            <Dropdown item text="Veranstaltungen">
                                <Dropdown.Menu>
                                    <DropdownItem as={Link}
                                        to={'/tournament'}
                                        text="Turniere" />
                                    <DropdownItem as={Link}
                                        to={'/event'}
                                        text="Messe & Firmenevents" />
                                </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item icon="facebook" href="https://www.facebook.com/Finnigansharpnuernberg" target='_blank' />
                            <Menu.Item icon="instagram" href="https://www.instagram.com/finnigans_harp_nuremberg/" target='_blank' />
                            <Menu.Item icon="whatsapp" href="https://whatsapp.com/channel/0029VaKzCIf05MUjVyTOya3L" target='_blank' />
                            <Menu.Item icon="youtube" href="https://www.youtube.com/@FinnigansHarp1984/streams" target='_blank' />
                            {(!isLoggedIn || !user) ? (
                                <Menu.Item position="right">
                                    <Button
                                        onClick={() => {
                                            openModal(<LoginForm />);
                                        }}
                                        size="huge"
                                    >
                                        Login
                                    </Button>
                                </Menu.Item>
                            ) : (
                                <Menu.Item position="right">
                                    <Dropdown
                                        pointing="top left"
                                        text={user.displayName}
                                        icon="user"
                                        floating
                                        labeled
                                        button
                                        className="icon"
                                    >
                                        <Dropdown.Menu>
                                            <DropdownItem
                                                as={Link}
                                                to={'/createcompetition'}
                                                text="Competition erfassen"
                                                icon="edit"
                                            />
                                            <DropdownItem
                                                onClick={logout}
                                                text="Logout"
                                                icon="power"
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Item>
                            )}
                        </Container>
                    </MediaQuery >

                </Menu >

            </div>
        </div>
    );
};

export default observer(NavBar);
